<template>
  <div>
    <div v-show="viewMode==='grid'">
      <grid-toolbar
        :ref="gridToolbarName"
        :page-name="pageName"
        :grid-ref-name="gridName"
        :selected-rows-data="selectedRowsData"
        :title="title">
        <template slot="toolbarSlotBefore">
          <b-button-group
            v-if="gridConfig!==null && gridConfig.actionPageBtn!==undefined"
            size="sm"
          >
            <div
              v-for="(btn, index) in gridConfig.actionPageBtn"
              :key="index">
              <b-button
                v-if="(btn.role === undefined || (btn.role !== undefined && $can(btn.role, 'role'))) && ((!trashedMode && btn.trashedModeOnly===undefined) || (trashedMode && (btn.trashedMode || btn.trashedModeOnly)))"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :title="btn.title"
                variant="primary"
                size="sm"
                style="margin-right: 2px"
                @click="actionPageClicked(btn.command)"
              >
                <feather-icon
                  :icon="btn.icon"
                  size="12"/>
              </b-button>
            </div>
          </b-button-group>
        </template>
        <template
          v-if="gridConfig!==null && gridConfig.actionMultipleRowBtn!==undefined"
          slot="selectedRowsCommandItem">
          <div
            v-for="(btn, index) in gridConfig.actionMultipleRowBtn"
            :key="index">
            <b-dropdown-item
              v-if="(btn.role === undefined || (btn.role !== undefined && $can(btn.role, 'role'))) && ((!trashedMode && btn.trashedModeOnly===undefined) || (trashedMode && (btn.trashedMode || btn.trashedModeOnly)))"
              @click="selectedRowsCommand(btn.command)">
              <span class="text-uppercase">{{ btn.label }}</span>
            </b-dropdown-item>
          </div>
        </template>
      </grid-toolbar>

      <dx-data-grid
        :ref="gridName"
        :on-content-ready="gridContentReady"
        :focused-column-index.sync="focusedRowIndex"
        :on-key-down="gridKeyDown"
        :on-focused-row-changing="gridFocusedRowChanging"
        :on-focused-row-changed="gridFocusedRowChanged"
        :on-focused-cell-changed="gridFocusedCellChanged"
        :on-focused-cell-changing="gridFocusedCellChanging"
        :on-selection-changed="selectionChanged"
        :height="gridHeight">
        <div
          slot="actionTpl"
          slot-scope="data">
          <b-dropdown
            :ref="gridName+'RowAction'+data.data.data.id"
            variant="link"
            no-caret
            size="sm">
            <template
              #button-content
            >
              <feather-icon
                icon="MoreHorizontalIcon"
                size="16"
                class="align-middle text-body p-0"
              />
            </template>
            <div
              v-for="(btn, index) in gridConfig.actionRowBtn"
              :key="index">
              <b-dropdown-item
                v-if="(btn.role === undefined || (btn.role !== undefined && $can(btn.role, 'role'))) && ((!trashedMode && btn.trashedModeOnly===undefined) || (trashedMode && (btn.trashedMode || btn.trashedModeOnly)))"
                class=""
                @click="actionRowClicked({mode:btn.command, rowData:data.data.data})">
                <feather-icon :icon="btn.icon"/>
                <span class="align-middle ml-50 text-uppercase">{{ btn.label }}</span>
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </div>

        <dx-column-chooser mode="select"/>
      </dx-data-grid>
    </div>
    <div
      v-if="viewMode==='form'"
      class="mb-3">
      <form-app
        :ref="formName"
        :form-data-id="formDataId"
        :title="title"
        :form-name="formName"
        :on-form-close="formOnHide"
        :on-form-add-new="formOnAddNew"
        :form-clone-mode="formCloneMode"
        :on-form-before-save="formBeforeSave">
        <div
          v-if="$refs[formName]!==undefined"
          slot="additionalForm"
          slot-scope="data">
          <hr class="mt-1 mb-1">
          <div class="row">
            <div class="col-md-6 col-sm-12 d-flex">
              <DxTreeList
                ref="menuList"
                :data-source="menuList"
                :show-borders="true"
                :show-row-lines="true"
                :show-column-lines="true"
                :allow-column-reordering="true"
                :column-auto-width="false"
                :auto-expand-all="true"
                :height="treeHeight"
                key-expr="id"
                parent-id-expr="parent"
                width="600"
              >
                <DxSelection
                  :recursive="false"
                  mode="multiple"
                />
                <DxColumn
                  caption="Menu & Permission"
                  data-field="text"
                  width="400"
                />
                <DxColumn
                  caption="Tipe"
                  data-field="type"
                  width="200"
                />
              </DxTreeList>
            </div>
          </div>
          {{ data.data }}
        </div>
      </form-app>
    </div>
    <audit-grid
      v-if="viewMode==='audit'"
      :ref="auditGridName"
      :title="title"
      :name="auditGridName"
      :hide-event="formOnHide"/>
  </div>
</template>

<script>
import { DefaultPageData } from '@/modules/mixins/data'
import { nextTick } from '@vue/composition-api'
import {
  DxTreeList,
  DxColumn,
  DxSelection
} from 'devextreme-vue/ui/tree-list'
import axiosIns from '../libs/axios'

export default {
  components: {
    DxTreeList,
    DxColumn,
    DxSelection
  },
  props: {
    pageName: {
      type: String,
      default: 'Role'
    },
    title: {
      type: String,
      default: 'Role'
    },
    gridHeight: {
      type: Number,
      default: function _default() {
        return window.innerHeight - 198
      }
    },
    treeHeight: {
      type: Number,
      default: function _default() {
        return window.innerHeight - 270
      }
    }
  },
  data() {
    const data = { ...DefaultPageData }
    this.$stateMerge(data, {
      viewMode: 'grid',
      gridName: `${this.pageName}Grid`,
      gridToolbarName: `${this.pageName}GridToolbar`,
      actionRowButtonName: `${this.pageName}GridActionRowBtn`,
      auditGridName: `${this.pageName}GridAudit`,
      formName: `${this.pageName}Form`,
      formOnHide: `${this.pageName}FormHideEvent`,
      formOnAddNew: `${this.pageName}FormAddNewEvent`,
      delRowsRoute: 'roleDelBatch',
      restoreRowsRoute: 'roleRestoreBatch',
      menuList: null
    })
    return data
  },
  created() {
    const vm = this
    vm.gridCreated()

    vm.$events.$on(`${vm.formName}FormMountedEvent`, () => {
      const formRef = this.$refs[vm.formName]

      if (formRef !== undefined && formRef.formItemsSetStatus) {
        const { formDataEdit } = formRef
        axiosIns.get('menuTreeView')
          .then(resp => {
            this.menuList = resp.data.data
          })
          .then(() => {
            if (formDataEdit !== null) {
              console.log('formDataEdit', formDataEdit)
              this.$refs.menuList.instance.selectRows(formDataEdit.permissions)
            }
          })
      }
    })

    // eslint-disable-next-line no-unused-vars
    vm.$events.$on(`${vm.pageName}FormAddNewEvent`, data => {
      this.$refs.menuList.instance.clearSelection()
    })
  },
  mounted() {
    const vm = this
    vm.gridMount()
  },
  methods: {
    bindMoustrap() {

    },
    actionPageClicked(command) {
      if (command === 'addNew') {
        this.viewMode = 'form'
      }
    },
    actionRowClicked(command) {
      const vm = this
      console.log('actionRowClicked', command)
      setTimeout(() => {
        if (command.mode === 'editRow' || command.mode === 'cloneRow') {
          vm.$refs[`${vm.gridName}RowAction${command.rowData.id}`].hide()
          // eslint-disable-next-line radix
          vm.formDataId = parseInt(command.rowData.id)
          vm.formCloneMode = command.mode === 'cloneRow'
          vm.viewMode = 'form'
        } else if (command.mode === 'deleteRow') {
          vm.delRows([command.rowData.id], false)
        } else if (command.mode === 'restoreRow') {
          vm.restoreRows([command.rowData.id], false)
        } else if (command.mode === 'auditRow') {
          vm.$refs[`${vm.gridName}RowAction${command.rowData.id}`].hide()
          const route = `api/role/${command.rowData.id}/audit`
          vm.viewMode = 'audit'
          nextTick().then(() => {
            vm.$refs[vm.auditGridName].updateRoute(route)
          })
        } else {
          vm.msgShow('Unauthorized access', 'error')
        }
      })
    },
    selectedRowsCommand(command) {
      if (command === 'deleteRow') {
        const vm = this
        setTimeout(() => {
          vm.delRows(this.$_map(this.selectedRowsData, 'id'))
          vm.$refs[vm.gridToolbarName].$refs[`${vm.gridName}SelectedRowsButton`].hide()
        }, 200)
      }
    },
    formBeforeSave() {
      const { formData } = this.$refs[this.formName]

      return new Promise(done => {
        formData.permissions = []
        formData.menus = []
        const selectedRowKeys = this.$refs.menuList.instance.getSelectedRowKeys('all')
        formData.permissions = Array.from(new Set(Array.from(this.getAllPermissions(selectedRowKeys))))
        formData.menus = Array.from(new Set(Array.from(this.getAllParents(selectedRowKeys))))
        console.log('formData.permissions', formData.permissions)
        console.log('formData.menus', formData.menus)
        done(true)
      }).catch(error => {
        this.msgShow(error, 'error')
        return false
      })
    },
    testClicked() {
      const vm = this
      const { formData } = vm.$refs[vm.formName]
      formData.menus = []
      formData.permissions = this.$refs.menuList.instance.getSelectedRowKeys('leavesOnly')
      formData.menus = Array.from(new Set(Array.from(this.getAllParents(formData.permissions))))
    },
    * getAllPermissions(arr) {
      const vm = this
      // eslint-disable-next-line no-restricted-syntax,guard-for-in
      for (const i in arr) {
        const node = vm.$refs.menuList.instance.getNodeByKey(arr[i])
        console.log('node', node)
        if (!node.hasChildren) {
          yield node.key
        }
      }
    },
    * getAllParents(arr) {
      const vm = this
      // eslint-disable-next-line no-restricted-syntax,guard-for-in
      for (const i in arr) {
        const node = vm.$refs.menuList.instance.getNodeByKey(arr[i])

        if (node.hasChildren) {
          yield node.key
        }

        if (node.parent.level >= 0) {
          yield node.parent.key

          const resArray = Array.from(vm.getAllParents([node.parent.key]))
          // eslint-disable-next-line no-undef,guard-for-in,no-restricted-syntax
          for (const j in resArray) {
            yield resArray[j]
          }
        }
      }
    }
  }
}
</script>
